import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import Layout from '../components/global/layout/layout';
import Styles from './news.module.scss';

export default props => {
    return (
        <Layout
            wrapperClassName="bg-pattern-gold"
            title={"News: " + props.data.news.data.title.text}
            titleDivider=" - "
            titleSuffix="Schloss Westerburg"
            description={props.data.news.data.shorttext.text}
            image={props.data.news.data.image.thumbnail.localFile.publicURL}
        >
            <section className="container py-5">
                <div className="row pt-5">
                    <div className="col-12 pt-5">
                        <h1 className="color-gold">{props.data.news.data.title.text}</h1>
                        <Img className="my-4"
                            fluid={props.data.news.data.image.localFile.childImageSharp.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt=""></Img>
                        {
                            props.data.news.data.body.map(slice => {
                                switch(slice.slice_type) {
                                    case 'text': return TextSlice(slice);
                                    default: return '';
                                }
                            })
                        }
                    </div>
                </div>
            </section>

        </Layout>
    )
}

const TextSlice = slice => <div dangerouslySetInnerHTML={{ __html: slice.primary.text.html }} className={`${Styles.textSlice}`}></div>;

export const pageQuery = graphql`
  query PostByUid($uid: String!) {
    news: prismicNewsArticle(uid: {eq: $uid}) {
        uid
        publish_date: first_publication_date(formatString: "DD.MM.YYYY")
        data {
            title {
                text
            }
            shorttext {
                text
            }
            image {
                thumbnail {
                    localFile {
                        publicURL
                    }
                }
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 2400) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
            body {
                ... on PrismicNewsArticleBodyText {
                    slice_type
                    primary {
                        text {
                            html
                        }
                    }
                }
            }
        }
    }
  }
`
